<template>
  <div class="flex flex-col items-center justify-center pt-6 pb-12">
    <img src="@/assets/img/thinking_grandma.svg" alt="" />
    <div class="text-center max-w-md flex flex-col gap-6 items-center">
      <h1
        class="
          flex
          items-center
          justify-center
          gap-2
          mt-12
          text-2xl
          font-medium
        "
      >
        <CloseIcon class="text-red-600"></CloseIcon> Vaya, la orden ha fallado
      </h1>
      <p class="text-gray-500">
        No podemos decirte qué ha pasado concretamente, consulta la ayuda para
        obtener más información o inténtalo de nuevo.
      </p>
      <BaseButton class="max-w-xs" @click="$emit('click:tryAgain')" primary>
        Intentar de nuevo
      </BaseButton>
    </div>
  </div>
</template>

<script>
import CloseIcon from "vue-material-design-icons/Close.vue";

export default {
  components: {
    CloseIcon,
  },
};
</script>
