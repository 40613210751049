












































































































































































































































import { IAmount, ICurrencyKey } from "@/core/types";
import { Component, Vue } from "vue-property-decorator";
import CompanyLogo from "../components/base/CompanyLogo.vue";
import InfoIcon from "vue-material-design-icons/InformationOutline.vue";
import { MarketStatus } from "@/core";
import OrderError from "../components/orders/OrderError.vue";
import { ISendOrderRes } from "@/core/Orders/OrdersUseCases";

@Component({
  components: {
    CompanyLogo,
    InfoIcon,
    OrderError,
  },
})
export default class extends Vue {
  shares = 1;
  loading = true;
  step: "first_step" | "second_step" | "success" | "error" = "first_step";

  async mounted(): Promise<void> {
    this.loading = true;
    const assetId = this.$route.params.id;
    await Promise.all([
      this.$store.dispatch("sell/fetchAsset", assetId),
      this.$store.dispatch("sell/fetchPrices", assetId),
      this.$store.dispatch("sell/fetchCurrentShareTotal", assetId),
    ]);
    this.loading = false;
  }

  get currentShareTotal(): number {
    return this.$store.state.sell.currentShareTotal;
  }
  get subtotal(): IAmount {
    return {
      value: this.shares * this.assetPrice.value,
      currency: this.assetCurrency,
    };
  }
  get assetCurrency(): ICurrencyKey {
    return this.$store.state.sell.asset.currency;
  }
  get asset(): IAmount {
    return this.$store.state.sell.asset;
  }
  get assetPrice(): IAmount {
    return this.prices.assetPrice.value;
  }
  get prices() {
    return this.$store.state.sell.prices;
  }
  get estimate() {
    return this.$store.state.sell.estimate;
  }
  get estimatedIncome(): IAmount {
    if (this.step === "first_step") {
      const settlementCost = this.prices.settlementCost.value || 0;
      const value =
        this.prices.costInClientsCurrencyWithoutCommission.value * this.shares -
        settlementCost;
      const amount = {
        value,
        currency: this.prices.costInClientsCurrencyWithoutCommission.currency,
      };

      return amount;
    } else if (this.step === "second_step") {
      return this.estimate.totalCost;
    } else {
      return {
        value: 0,
        currency: this.assetCurrency,
      };
    }
  }
  get marketIsOpen(): boolean {
    return MarketStatus.open;
  }
  get orderRes(): ISendOrderRes {
    return this.$store.state.sell.orderRes;
  }
  get reviewDisabled(): boolean {
    if (this.shares <= 0) {
      return true;
    } else if (this.shares > this.currentShareTotal) {
      return true;
    } else return false;
  }

  async continueStep1(): Promise<void> {
    this.loading = true;
    await this.$store.dispatch("sell/fetchEstimate", {
      shares: this.shares,
      assetId: this.$route.params.id,
    });
    this.step = "second_step";
    this.loading = false;
  }
  async continueStep2(): Promise<void> {
    this.loading = true;
    try {
      await this.$store.dispatch("sell/sendSellOrder", {
        shares: this.shares,
        assetId: this.$route.params.id,
      });
      this.step = "success";
    } catch (error) {
      console.error("Error sending order, ", error);
      this.step = "error";
    } finally {
      this.loading = false;
    }
  }
  continueStep3(): void {
    this.$router.push("/producto/" + this.$route.params.id);
  }

  openBuyHelpModal(): void {
    const modalComponent = this.marketIsOpen
      ? () => import("../components/orders/OpenMarketHelpModal.vue")
      : () => import("../components/orders/ClosedMarketHelpModal.vue");

    this.$store.dispatch("modal/setComponent", modalComponent);
    this.$store.dispatch("modal/show");
  }

  tryAgain(): void {
    this.step = "first_step";
  }
}
